import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'

export default function OverviewStackbar({ stackData, data }) {
  const [chartData, setChartData] = useState([]);
  useEffect(() => {
    if( stackData !== undefined)
    {setChartData(stackData)}
  }, [stackData])
  const xLabels = [];
  const isDateBased = chartData[0] && chartData[0].hasOwnProperty('Date');
  const months = chartData.map((item) => parseInt(item.month)); 
  const dropoutCount = chartData.map((item) => parseInt(item.dropout_count));
  const completedCount = chartData.map((item) => parseInt(item.completed_count));

  const getMonthName = (monthNumber) => {
    const monthNames = [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
    return monthNames[parseInt(monthNumber) - 1];
  };
  const getFormattedDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = getMonthName(date.getMonth() + 1);
    const year = date.getFullYear();
  
    return `${day}-${month}`;
  };
  
  // Example usage
  const formattedDate = getFormattedDate('2023-11-01');
  console.log(formattedDate); // Output: 1-Nov-2023
  

  if (isDateBased) {
    chartData.forEach((item) => {
      xLabels.push(getFormattedDate(item.Date));
    });
  } else {
    chartData.forEach((item) => {
      xLabels.push(getMonthName(item.month));
    });
  }

  const series = [{
    name: data.series_name[0],
    data: dropoutCount,
    style: {
      fontFamily: "Poppins",
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "24px",
      letterSpacing: "0em",
      textAlign: "center",
    }
  }, {
    name: data.series_name[1],
    data: completedCount
  }];
  const options = {
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
      toolbar: false
    },
    plotOptions: {
      bar: {
        borderRadius: 5.33,
        horizontal: false,
        dataLabels: {
          enabled: false,
        }
      },
    },
    stroke: {
      width: 1,
    },
    colors: data.colors,
    title: {
      text: data.label,
      align: 'center',
      style: {
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        letterSpacing: "0em",
        textAlign: "center",
      }
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: xLabels,
      labels: {
        style: {
          fontFamily: "Poppins",
          fontSize: "12px",
          fontWeight: 500,
          lineHeight: "18px",
          letterSpacing: "0em",
          textAlign: "center",
          Opacity: '50%'
        }
        // formatter: function (val) {
        //   return val + "K"
        // }
      }
    },
    yaxis: {
      title: {
        text: undefined
      },
      label: {
        style: {
          fontFamily: "Poppins",
          fontSize: "12px",
          fontWeight: 500,
          lineHeight: "18px",
          letterSpacing: "0em",
          textAlign: "center",
          Opacity: '50%'
        }
      }
    },
    tooltip: {
      y: {
        // formatter: function (val) {
        //   return val + "K"
        // }
      }
    },
    fill: {
      opacity: 1
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      offsetX: 40
    }
  }

  return (
    <>
     
      <div className="col-xl-4 col-md-6 col-12 mb-4">
        <div className="card-item px-3 flex-column">
          {/* <label className="font-16 fw-500 mb-3 text-center w-100">
            {data.label}
          </label> */}
          <ReactApexChart
            options={options}
            series={series}
            type="bar"
            height={300}
            width='100%'
          />
          {/* <div className="overview-chart">
                <img
                  src ={data.img}
                  className="w-100"
                  alt="chart images"
                />
              </div> */}
        </div>
      </div>
    </>
  )
}





