import React from 'react';
import OverviewCard from './OverviewCard';
import OverviewStackbar from './OverviewStackbar';
import OverViewFunnelChart from './OverViewFunnelChart';

function Overview(props) {
  console.log(props ,'props');
  return (<>
    <div className="card card-overview mb-4">
      <div className="card-body pb-0">
        <h3 className="card-title">Overview</h3>
        <div className="row">
          <OverviewCard data={{
            'Label': "Leads",
            'value': props.leads,
            'img': "images/dashboard-images/overview-leads.svg"
          }} />
          <OverviewCard data={{
            'Label': "KYC Done",
            'value': props.kycDone,
            'img': "images/dashboard-images/overview-kyc-done.svg"
          }} />
          <OverviewCard data={{
            'Label': "Onboarded",
            'value': props.onBoarded,
            'img': "images/dashboard-images/overview-onboarded.svg"
          }} />
          <OverviewCard data={{
            'Label': "Dropouts",
            'value': props.dropouts,
            'img': "images/dashboard-images/overview-dropouts.svg"
          }} />
          <OverviewStackbar 
           stackData = {props.stackData}
          data={{
            'label': 'KYC Done vs Dropouts',
            'img': "images/dashboard-images/chart-2.png",
             'colors': ['#F15B4D', '#05CD99'],
             "series_name" : ['Dropouts' ,'KYC Done']
          }} />
          <OverviewStackbar data={{
            'label': 'KYC Done vs Onboarded',
            'img': "images/dashboard-images/chart-2.png",
            'colors' : ['#FFDE2D' ,'#05CD99' ],
            "series_name" : ['Onboarded' ,'KYC Done'],
          }} 
          // stackData = 
          // {props.stackData}
          />
          <OverViewFunnelChart kycDone = {props.kycDone} leads = {props.leads} onBoarded = {props.onBoarded} dropouts = {props.dropouts}/>
        </div>
      </div>
    </div>

  </>)
}
export default Overview;