import React, { useEffect, useState } from 'react';

function TimeElapsed(props) {
  const [dataInSeconds, setDataInSeconds] = useState({})
  useEffect(() => {
    if (props.data) {
      setDataInSeconds(props.data);
    }
  }, [props.data])
  const dataInMinutes = {};
  for (const key in dataInSeconds) {
    const seconds = dataInSeconds[key];
    const minutes = Math.floor(seconds / 60); // Round down to the nearest integer
    dataInMinutes[key] = `${minutes} min`;
  }
  return (<>
    <div className="card card-time-elapsed mb-4">
      <div className="card-body">
        <h3 className="card-title">Time Elapsed between KYC Steps</h3>
        <div className="card-item p-3 table-responsive">
          <div className="time-journey">
            <ul>
              <li>
                <div className="time-name tn-top">Mobile Verified </div>
                <div className="time-icon"> </div>
                <div className="time-bottom">{dataInMinutes["2"]} </div>
              </li>
              <li>
                <div className="time-upper">{dataInMinutes["4"]}</div>
                <div className="time-name tn-bottom">Email Verified </div>
                <div className="time-icon"> </div>
              </li>
              <li>
                <div className="time-name tn-top">Pan Verified </div>
                <div className="time-icon"> </div>
                <div className="time-bottom">{dataInMinutes["5"]}</div>
              </li>
              <li>
                <div className="time-upper">{dataInMinutes["6"]}</div>
                <div className="time-name tn-bottom">
                  KRA/Digilocker
                  <br />
                  Completed{" "}
                </div>
                <div className="time-icon"> </div>
              </li>
              <li>
                <div className="time-name tn-top tn-top-high">
                  Personel Details{" "}
                </div>
                <div className="time-icon"> </div>
                <div className="time-bottom">{dataInMinutes["7"]}</div>
              </li>
              <li>
                <div className="time-upper">{dataInMinutes["8"]}</div>
                <div className="time-name tn-bottom">
                  Occupational
                  <br />
                  Details
                </div>
                <div className="time-icon"> </div>
              </li>
              <li>
                <div className="time-name tn-top">Bank Details </div>
                <div className="time-icon"> </div>
                <div className="time-bottom">{dataInMinutes["9"]}</div>
              </li>
              <li>
                <div className="time-upper">{dataInMinutes["10"]}</div>
                <div className="time-name tn-bottom">
                  Segment
                  <br /> Details{" "}
                </div>
                <div className="time-icon"> </div>
              </li>
              <li>
                <div className="time-name tn-top tn-top-high">
                  Document Upload
                </div>
                <div className="time-icon"> </div>
                <div className="time-bottom">{dataInMinutes["14"]}</div>
              </li>
              <li>
                <div className="time-upper">{dataInMinutes["15"]}</div>
                <div className="time-name tn-bottom">E sign</div>
                <div className="time-icon"> </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>


  </>)
}
export default TimeElapsed;