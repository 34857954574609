import React from 'react'

export default function AverageTimeCard({data}) {
  return (
   <><div className="card-item p-2 h-auto mb-3 flex-column">
   <p className="fs-14 lh-21 mb-1 mw-190">
    {data.label}
   </p>
   <h4 className="text-end fs-20 mb-0">{data.time}</h4>
 </div>
   </>
  )
}
