import React from 'react'; 



function OverviewCard({ data }) {
    return (<>
        <div className="col-lg-3 col-sm-6 col-12 mb-3 mb-md-4">
            <div className="card-item align-items-center">
                <div className="overview-img">
                    <img
                        src={data.img}
                        alt="overview image"
                    />
                </div>
                <div className="overview-content">
                    <label>{data.Label} </label>
                    <span>{data.value} </span>
                </div>
            </div>
        </div>
    </>)

}
export default OverviewCard;