import React, { useEffect, useState } from 'react'
import Chart from "react-apexcharts";

export default function VerificationLineChart(props) {

  const [chartData, setChartData] = useState([ ]);

  useEffect(() => {
    if (Array.isArray(props.data)) {
      setChartData(props.data);
    }
  }, [props.data])

  const getMonthName = (monthNumber) => {
    const monthNames = [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
    return monthNames[parseInt(monthNumber) - 1];
  };
  const getFormattedDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = getMonthName(date.getMonth() + 1);
    const year = date.getFullYear();
  
    return `${day}-${month}`;
  };

  
  if (chartData.length === 0) {
    // Data is still loading, you can render a loading indicator here.
    return <div>Loading...</div>;
  }

  const xLabels = [];
  const isDateBased = chartData[0] && chartData[0].hasOwnProperty('Date');
  if (isDateBased) {
    chartData.forEach((item) => {
      xLabels.push(getFormattedDate(item.Date));
    });
  } else {
    chartData.forEach((item) => {
      xLabels.push(getMonthName(item.Month));
    });
  }
  // const months = chartData.map((entry) => entry.Month);
  const diyValues = chartData.map((entry) => parseInt(entry.diy));
  const assistedValues = chartData.map((entry) => parseInt(entry.assisted));

  const series = [
    {
      name: "DIY",
      data: diyValues,
    },
    {
      name: "Assisted",
      data: assistedValues,
    },
  ];
  const options = {
    chart: {
      id: "basic-bar",
      toolbar: {
        show: false
      }
    },
    responsive: [{
      breakpoint: undefined,
      options: {},
    }],

    xaxis: {
      categories: xLabels
    },
    markers: {
      size: 1
    },

    colors: ['#5367FC', '#F15B4D'],
    title: {
      text: 'DIY vs Assisted',
      align: '',
      style:
      {
        fontFamily: 'Poppins',
        fontSize: '20px',
        fontWeight: 500,
        lineHeight: '31px',
        letterSpacing: '0em',
        textAlign: 'center',
      }

    },
    //  dataLabels: {
    //       enabled: true,
    //     },
  };


  return (
    <>
      <div className="app">
        <div className="row">
          <div className="mixed-chart ">
            {chartData.length === 0 ?
              <div>Loading...</div> :
              <Chart
                options={options}
                series={series}
                type="line"
                width="100%"
              />}

          </div>
        </div>
      </div>
    </>
  )
}




