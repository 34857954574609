import React, { useEffect, useState } from 'react';
import VerificationLineChart from '../Verification/VerificationLineChart';
import DropoutsLineChart from './DropoutsLineChart';

function StepwiseKYC(props) {


  const originalData = [
    {
        "dropout_stepname": "Stage : Mobile",
        "dropout_step": 1,
        "dropout_count": 9
    },
    {
        "dropout_stepname": "Stage : Mobile",
        "dropout_step": 1,
        "dropout_count": 17
    },
    {
        "dropout_stepname": "Stage : Email",
        "dropout_step": 2,
        "dropout_count": 0
    },
    {
        "dropout_stepname": "Stage : Email",
        "dropout_step": 2,
        "dropout_count": 0
    },
    {
        "dropout_stepname": "Stage : Pan Verified",
        "dropout_step": 3,
        "dropout_count": "499"
    },
    {
        "dropout_stepname": "Stage : KRA\/Digilocker Completed",
        "dropout_step": 4,
        "dropout_count": "15"
    },
    {
        "dropout_stepname": "Stage : Personal Details",
        "dropout_step": 5,
        "dropout_count": "20"
    },
    {
        "dropout_stepname": "Stage : Occupational Details",
        "dropout_step": 6,
        "dropout_count": "46"
    },
    {
        "dropout_stepname": "Stage : Bank Details",
        "dropout_step": 7,
        "dropout_count": "75"
    },
    {
        "dropout_stepname": "Stage : Segments",
        "dropout_step": 8,
        "dropout_count": "19"
    },
    {
        "dropout_stepname": "Stage : Document upload",
        "dropout_step": 9,
        "dropout_count": 0
    },
    {
        "dropout_stepname": "Stage : Document upload",
        "dropout_step": 9,
        "dropout_count": 0
    },
    {
        "dropout_stepname": "Stage : Document upload",
        "dropout_step": 9,
        "dropout_count": 0
    },
    {
        "dropout_stepname": "Stage : Document upload",
        "dropout_step": 9,
        "dropout_count": 0
    },
    {
        "dropout_stepname": "Stage : Esign Done",
        "dropout_step": 10,
        "dropout_count": "34"
    },
    {
        "dropout_stepname": "Stage : Esign Done",
        "dropout_step": 10,
        "dropout_count": "34"
    }
];

   ///////
  console.log(props.data ,'stepwiseKYC')
  const [chartData , setChartData] = useState([]);
  useEffect(() => {
    if (Array.isArray(props.data)) {
      setChartData(props.data);
    }
  }, [props.data])
  const groupedData = chartData.reduce((accumulator, item) => {
    const existingItem = accumulator.find((groupedItem) => groupedItem.dropout_step === item.dropout_step);

    if (existingItem) {
        existingItem.dropout_count += parseInt(item.dropout_count, 10) || 0;
    } else {
        accumulator.push({ ...item });
    }

    return accumulator;
}, []);

console.log(groupedData ,'groupedData');

const formattedData = groupedData.map(obj => {
  obj.dropout_stepname = obj.dropout_stepname.replace('Stage :', '').trim();
  return obj;
});

  return (<>
    <div className="card card-stepwise-kyc mb-4">
      <div className="card-body pb-0">
        <h3 className="card-title">Stepwise KYC Dropouts</h3>
        <div className="row">
          <div className="col-xl-8 col-lg-7 col-12 mb-4">
            <div className='d-flex align-items-center w-100 h-100'>
            <div className="h-auto d-block w-100">
              <DropoutsLineChart width='100%'
               data = {props.graphData}
                duration = {props.duration}/>
            </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-5 col-12 mb-4">
            <div className="card-item align-items-center p-0 h-auto">
              <table className="table table-hover table-stage mb-1">
                <thead>
                  <tr>
                    <th className="bg-gray-light">Stage</th>
                    <th className="bg-gray-light">No. Of Dropouts</th>
                  </tr>
                </thead>
                <tbody>
                  { chartData.length === 0 ? <div>Loading..</div> :
                  formattedData.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="dot-dropouts" style={{ backgroundColor: item.color }} />{" "}
                          <span>{item.dropout_stepname}</span>
                        </div>
                      </td>
                      <td>{item.dropout_count}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

  </>)
}
export default StepwiseKYC;