import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts';

const OverViewFunnelChart = (props) => {
  const [chartData, setChartData] = useState(null); // Initialize chartData as null
  console.log(props, 'funnel');
  useEffect(() => {
    // Check if all the necessary data is available
    if (props.kycDone !== undefined && props.leads !== undefined && props.onBoarded !== undefined) {
      // Update chartData when all data is available
      setChartData([
        parseInt(props.leads),
        parseInt(props.kycDone),
        // parseInt(props.dropouts),
      ]);
    }
  }, [props.kycDone, props.leads, props.onBoarded ,props.dropouts]); // Monitor changes in these props

  if (chartData === null) {
    // Data is not yet available, you can render a loading indicator or message here
    return <div>Loading...</div>;
  }
  // const [charts, setCharts] = useState( [parseInt(props.kycDone), parseInt(props.leads), parseInt(props.onBoarded)])
   const   series =[
        {
          data: chartData,
        },
      ];
   const   options ={
        chart: {
          type: 'bar',
          height: 350,
          toolbar: false,
          
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
            barHeight: '50px',
            distributed: true,
            isFunnel: true,
          },
        },
        colors : ['#5367FC' ,'#05CD99' ,'#FFDE2D'],
// colors :['#5367FC','#05CD99','#FFDE2D','#5367FC','#5367FC'],
        dataLabels: {
          enabled: true,
          formatter: function (val, opt) {
            return opt.w.globals.labels[opt.dataPointIndex] + ':  ' + val
            // return  val
          },
          dropShadow: {
            enabled: true,
          },
        },
        // title: {
        //   text: 'Recruitment Funnel',
        //   align: 'middle',
        // },
        xaxis: {
          // categories: [
          //  'Leads','KYC Done' ,'OnBoarded'
          // ],
          categories: [
            'Leads','KYC Done' 
           ],
        },
        legend: {
          show: true,
          position: 'bottom',
          // fontSize : '12px',
          
        
          // style: {
          //   fontFamily: "Poppins",
          //   fontSize: "3px",
          //   fontWeight: 500,
          //   lineHeight: "18px",
          //   letterSpacing: "0em",
          //   textAlign: "center",
          //   Opacity: '50%'
          // }
        },
        tooltip: {
          enabled: false}
      }


    // })

  return (
    <>
      <div className="col-xl-4 col-md-6 col-12 mb-4">
        <div className="px-2 flex-column"></div>
      

        <label className="font-16 fw-500 mb-3 text-center w-100 d-none d-xl-block">
          &nbsp;
        </label>
        <div className="overview-chart m-0" >
          <div className="overview-chart-3 m-0">
          <ReactApexChart options={options} series={series} type="bar" width='100%' height='250px' />
          </div>
        </div>
      </div>
    </>
  )
}

export default OverViewFunnelChart




