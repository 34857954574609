import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import {useNavigate} from "react-router-dom"


import 'bootstrap/dist/css/bootstrap.css';
import './css/style.css';
import './css/responsive.css';

import IndexPage from './components/IndexPage';
import VerificationLineChart from './components/Verification/VerificationLineChart';
import barChart from './components/barCharts';
import LineChart from './components/Dropouts/LineChart';

function App() {
  return (
    <Routes >      
      <Route path="/" Component={IndexPage} />
      <Route path='/charts' Component={VerificationLineChart}    /> 
      <Route path='/calendar' Component={LineChart} />
    </Routes> 
  );
}

export default App;