import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
const TreeChart =(props)=>{
   
   const [data, setData] = useState({});
    useEffect(()=>{
        if(props.data !== undefined){
            setData(props.data);
        }
       },[props])

  const chartData = Object.entries(data).map(([x, y]) => ({
        x,
        y: [parseInt(y, 10)],
      }));
   const series = [
        {
            data:  chartData
        }
    ];
  const options = {
        legend: {
            show: true
        },
        chart: {
            toolbar : false,
            // height: 250,
            type: 'treemap'
        },
        title: {
            text: 'Lead Source',
            style : {
                fontFamily: 'Poppins',
                fontSize: '20px',
                fontWeight: 500,
                lineHeight: '30px',
                letterSpacing: '-0.0375em',
                textAlign: 'left'
              }
        },
        colors : ['#FDE562','#00FFC1','#FDE562','#0A3294'],
    }
return (
    <>
    <div id="chart p-0">
        <ReactApexChart options={options} series={series} type="treemap" width='100%' />
    </div>
    </>)
}
export default TreeChart
