import React, { useState } from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import PageNavigations from './PageNavigations';
import Overview from './Overview/Overview';
import Verification from './Verification/Verification';
import JourneySource from './JourneySource/JourneySource';
import LeadSource from './LeadSource/LeadSource';
import AverageTimeChart from './AverageTime/AverageTimeChart';
import StepwiseKYC from './Dropouts/StepwiseKYC';
import { Dashboard } from './Dashboard';
import { Tab, Tabs } from 'react-bootstrap';

function IndexPage() {
  const [selectedDuration , setSelectedDuration] = useState('last_seven_days');
  const [selectedRole , setSelectedRole] = useState(1);

  const handleDurationSelecter =(e)=>{
    setSelectedDuration(e.target.value);
  }

  const handleRoleSelector=(key)=>{
    setSelectedRole(key);
  }

  return (<>
    <main className="main-wraper dashboard-home">
      <Header />
      <section className="main-sec">
        <Sidebar />
        <div className="main-content-box">
          <div className="main-content-box-wrap">
            {/* <PageNavigations /> */}
            <div className="page-navigations d-xl-flex justify-content-between">
              <div className="title-box">
                <h1 className="title-page">Welcome, Users!</h1>
              </div>
              <div className="d-sm-flex justify-content-xl-end mb-3 mb-xl-0">
                <Tabs defaultActiveKey={1} id="uncontrolled-tab-example"  onSelect={handleRoleSelector}>
                  <Tab eventKey={1} title="Total">
                  </Tab>
                  <Tab eventKey={2} title="DIY">
                  </Tab>
                  <Tab eventKey={3} title="Assisted" >
                  </Tab>
                </Tabs>
                <div className="ps-sm-4 mt-3 mt-sm-0">
                  <select className="input-select" value={selectedDuration} onChange={handleDurationSelecter}>
                    {/* <option value={'today'}>Today </option> */}
                    <option value={'last_seven_days'}>Last Week </option>
                    <option value={'current_month'}>Current Month </option>
                    <option value={'last_month'}>Last Month </option>
                    <option value={'last_three_month'}>Last 3 Months </option>
                    <option value={'last_six_month'}> last 6 Months </option>
                    <option value={'one_year'}>Last 12 Months </option>
                    {/* <option value={'custom_date_range'}>Custom </option> */}
                  </select>
                </div>
              </div>
            </div>
            <Dashboard
             role = {selectedRole}
             duration = {selectedDuration}/>
          </div>
        </div>
      </section>
    </main>

  </>)
}
export default IndexPage;