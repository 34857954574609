import React from 'react';
import VerificationLineChart from './VerificationLineChart';

function Verification(props) {
  const tabsArray = [
    { eventKey: '1', title: "" },
    { eventKey: '2', title: "Bigul" },
    { eventKey: '3', title: "Bonanza" }
  ];
  function findTitleByEventKey(eventKey, tabsArray) {
    const tab = tabsArray.find(tab => tab.eventKey === eventKey);
    // setRole(tab ? tab.title : '')
    return tab ? tab.title : '';
  }
  return (<>
    <div className="card card-verification mb-4">
      <div className="card-body pb-0">
        <h3 className="card-title">Verification</h3>
        <div className="row">
          <div className="col-xxl-3 col-lg-4 col-md-5 col-12 mb-4">
            <div className="card-item align-items-center p-0 h-auto">
              <table className="table table-hover table-verification mb-1">
                <thead>
                  <tr>
                    <th className="bg-gray-light">&nbsp; </th>
                    <th className="bg-gray-light">Rejected </th>
                    <th className="bg-gray-light">Verified </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    className={` ${findTitleByEventKey(props.role, tabsArray) === 'Bonanza' ? 'donutDisplay' : ''} `}
                  >
                    <td>DIY </td>
                    <td>{props.verificationData.bigulRejected}</td>
                    <td>{props.verificationData.bigulVerified}</td>
                  </tr>
                  <tr
                    className={` ${findTitleByEventKey(props.role, tabsArray) === 'Bigul' ? 'donutDisplay' : ''} `}>
                    <td>Assisted </td>
                    <td>{props.verificationData.bonanzaRejected} </td>
                    <td> {props.verificationData.bonanzaVerified}</td>
                  </tr>
                  <tr className={` ${findTitleByEventKey(props.role, tabsArray) === '' ? '' : 'donutDisplay'} `}>
                    <td>Total </td>
                    <td>{parseInt(props.verificationData.bigulRejected) + parseInt(props.verificationData.bonanzaRejected)}</td>
                    <td>{parseInt(props.verificationData.bigulVerified) + parseInt(props.verificationData.bonanzaVerified)} </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-xxl-9 col-lg-8 col-md-7 col-12 mb-4">
            <div className="h-auto">
              <VerificationLineChart data={props.graphData} />
              {/* <img
                    src="images/dashboard-images/dy-assisted.png"
                    alt="overview image"
                  /> */}
            </div>
          </div>
        </div>
      </div>
    </div>

  </>)
}
export default Verification;