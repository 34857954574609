import React from 'react';

function Sidebar(){   
    return (<>
<aside className="sidebar-box">
  <div className="sidebar-box-wrap">
    <div className="header-content-box align-items-center d-sm-flex d-lg-none">
      <div className="header-search-box">
        <form>
          <input
            type="search"
            className="input-search"
            placeholder="Search..."
          />
        </form>
      </div>
      <div className="header-user-box">
        <div className="header-user-box-wrap">
          <div className="header-notibox">
            <img src="images/header-images/notification.svg" alt="" />
            <label>7</label>
          </div>
          <div className="header-namebox">
            <span>
              <img src="images/header-images/user.svg" alt="" />
            </span>
            <div className="header-namebox-content">
              <span className="header-user-name">Jane Smith </span>
              <span className="header-user-post">Admin </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ul>
      <li className="active aside-nav-dasboard">
        <a href="#">Dashboard</a>
      </li>
      <li className="aside-nav-report">
        <a href="#">Generated Report</a>
      </li>
      <li className="aside-nav-logout">
        <a href="#">Log out</a>
      </li>
    </ul>
  </div>
</aside>
</>)
}
export default Sidebar;