import React from 'react';
import TreeChart from './TreeChart';

function LeadSource(props){   
  console.log(props ,'leadshour');
    return (<>

<div className="card card-lead-source p-0">
              <div className="card-body">
                  <TreeChart data = {props.data}/>
              </div>
            </div>


</>)
}
export default LeadSource;