import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

export default function AverageTimeBarChart(props) {
    const [kyc_verificationchartData , setkyc_verificationChartData] = useState([]);
    const [kyc_completionCharData , setkyc_completionChartData] = useState([]);

    useEffect(()=>{
        if (props.data) {
            if (props.data.kyc_verification) {
                setkyc_verificationChartData(props.data.kyc_verification);
            }
            if (props.data.kyc_completion) {
                setkyc_completionChartData(props.data.kyc_completion);
            }
        }
    },[props.data])

    const getMonthName = (monthNumber) => {
        const monthNames = [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
        return monthNames[parseInt(monthNumber) - 1];
      };
      const getFormattedDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = getMonthName(date.getMonth() + 1);
        const year = date.getFullYear();
      
        return `${day}-${month}`;
      };
    const xLabels = [];
    const isDateBased = kyc_verificationchartData[0] && kyc_verificationchartData[0].hasOwnProperty('VerificationDate');
    if (isDateBased) {
        kyc_verificationchartData.forEach((item) => {
        xLabels.push(getFormattedDate(item.VerificationDate));
      });
    } else {
        kyc_verificationchartData.forEach((item) => {
        xLabels.push((item.Month));
      });
    }
    // if(chartData === null){
    //     return <>Loading..</>
    // }
 
    const avgTimeToVerify = kyc_verificationchartData.map((item) => item.AverageTimeToVerifyKYC);
    const avgTimeToCompleteKYC = kyc_completionCharData.map((item) => item.AverageTimeToCompleteKYC);


    const series = [
        {
            name: "Verification",
            data: avgTimeToVerify
          },
          {
            name: "Completion",
            data: avgTimeToCompleteKYC
          }
   
]
    const options = {
        legend: {
            show: true
        },
        chart: {
            type: 'bar',
            height: 350
        },
        labels: ['Completion', 'Verification', 'Onboarding'],
        plotOptions: {
            bar: {
                borderRadius: 4,
                horizontal: false,
            }
        },
        dataLabels: {
            enabled: false
        },
        colors: ['#53DEFC', '#5367FC', '#0A3294'],
        xaxis: {
            categories:xLabels,
        },
        yaxis: {
            labels: {
                show: true,
                formatter: function (val) {
                    return val + ' sec';
                }
            }
        },
          legend: {
      position: 'top'
    },
    }


    return (<>
    {kyc_verificationchartData.length == 0? 'Loading..' : 
        <Chart options={options} series={series} type="bar" height={380} width='100%' />
    }
    </>
    )
}


