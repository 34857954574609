import React from 'react';
import {useState} from 'react';

function Header(){   
  const [isActive, setIsActive] = useState(false);
  const handleClick = event => {
     setIsActive(current => !current);
  };

    return (<>
  <header className="site-header" id={isActive ? 'sidebar-open' : ''}>
  <div className="header-wrap">
    <div className="logo-box">
      <a href="">
        <img src="images/header-images/bigul-logo.svg" alt="image" />
      </a>
    </div>
    <div className="header-content-box">
      <div className="header-search-box">       
          <input
            type="search"
            className="input-search"
            placeholder="Search..."
          />       
      </div>
      <div className="header-user-box">
        <div className="header-user-box-wrap">
          <div className="header-notibox">
            <img src="images/header-images/notification.svg" alt="" />
            <label>7</label>
          </div>
          <div className="header-namebox">
            <span>
              <img src="images/header-images/user.svg" alt="" />
            </span>
            <div className="header-namebox-content">
              <span className="header-user-name">Jane Smith </span>
              <span className="header-user-post">Admin </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="header-nav-icon d-inline-block d-lg-none" onClick={handleClick}>
      <div>
      </div>
    </div>
  </div>
</header>
</>)
}
export default Header;