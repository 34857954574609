import axios from "axios";


export const dkycUrl = 'https://dkyc.bonanzaonline.com/ekycapi';
export const dkycAppUrl = 'https://dkyc.bigul.app/ekycapi';
//  process.env.REACT_APP_BIGUL_BONANZA_BASE_URL;
export const apiKey = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjNhMzdiYjEyMTZlZmUyODg1NDAxNjRjNmQ3NmQ4NjZkY2YxM2IxMDhiZjcyZmFkMWUzMDNjYzYyZWI4ZjBjZGViNmMxNDFkNzRjYTRlYTJkIn0.eyJhdWQiOiI1IiwianRpIjoiM2EzN2JiMTIxNmVmZTI4ODU0MDE2NGM2ZDc2ZDg2NmRjZjEzYjEwOGJmNzJmYWQxZTMwM2NjNjJlYjhmMGNkZWI2YzE0MWQ3NGNhNGVhMmQiLCJpYXQiOjE2OTc1MzM3MTAsIm5iZiI6MTY5NzUzMzcxMCwiZXhwIjoxNzI5MTU2MTEwLCJzdWIiOiIxMiIsInNjb3BlcyI6W119.NXRXPgb00gp8FnWFtHXP6heIsi7DjwMCDJiwH-PnAPI33MdJxomTD1l58_ydRlBxlIIwbxTizmkE9vUpaxLbHJbYIs_5iOneDO85LuK-dc4SrAm9DvYGCDmSgs9O6QXnLI36iacU4E_vocGf5E3RZYW5jAv4VV9VaP0DAD0hEtPbsp0lU_c8D1rsScxgk86XZUbx7Tv_tcH4fTRmHVQ19Rvqa5M4uPYcEhz5QbFGOjgdcirQaExbP059Uy5gNcirS-kjYm96eRkC6mjylTLYSCP4kVr7cH7jTPZt73Taxb_dAhKdbZaopugf6Oymkf1cOod_OTldX53J2x4sY9wxjDhmAsR20F8YupH2bU9xr1J77FVsINsKRp5ME5rThCO6eyxAn6mb2xkTfj6TFXEI8m5taXemqGdaP-apeOEVsLvYgHI_ov3O-J85uOEeI6W1DOz3JDWsjM1Ak3ywdi7t7rdeL-gA2z2Y518TOhuoigpAGBiQzAX99MKUnyXWBeOBJD9AjLjkYxDnd2BU6quNKqsAfCF_Yoa6a_VjbqYUcimmb8JMSpixQkXg26rY2i5dJOZLwAvA01YMNtCxF4vrpN4Hy8-tiXe3Hy5-ppZwLeZBZy6qLVn33ixy9Z5ZWrRAj53Ii3O0a88cYAp9aDn3fqs_pgsCsumZJLfOrToM5_8";
// process.env.REACT_APP_DKYC_BONANZA_API_KEY;

export const apiAppKey = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImNkMDUxZGExMDc2MDg1NzVkOGE2NDZhZWM5ZGM0NTI1NGRiYjE4ZjM0OThiOThhZDYwYWI2MDI1ZGU2MjRlZDJhOGIxZTk5YTQ2OTk4NDJiIn0.eyJhdWQiOiI1IiwianRpIjoiY2QwNTFkYTEwNzYwODU3NWQ4YTY0NmFlYzlkYzQ1MjU0ZGJiMThmMzQ5OGI5OGFkNjBhYjYwMjVkZTYyNGVkMmE4YjFlOTlhNDY5OTg0MmIiLCJpYXQiOjE2ODk4NTM5NDYsIm5iZiI6MTY4OTg1Mzk0NiwiZXhwIjoxNzIxNDc2MzQ2LCJzdWIiOiIxMiIsInNjb3BlcyI6W119.FBVXv3ilHKnz0t_I5B1tg54kjwVzM59PMIVCH5F7NZtwqxumiq2I8HsNajHw1w-2oVxlkgHZHvHAx9TmlAJFC55ffdNo3URC3At4_nqMPMsJ2IhO-1FXEqX5iDGN7F3Fr42chvNw3caB7dNBG207zgpJm7XpK-18iqVm6oW034hwKHg8uHz8BhPd_0DW8i9ouQf0RI7BW-W6Q6tQtNyIcLwLrLunQtzAci4rqDaOViESqiCL8bb0ZLtNAtY_DsbLUzOQ1NJF5R9xLhH_yughbeNEdmQgKjuueDBKkiPWvqxZX4GdqtvZKxt4ZgpAHR2m8CvhYWL2HbwmSl9TiYDAvKcAi8gGgI06HodxToP1j2KftfG2c4tTrJ9i2hGbD9o7eV12EQ0ZnMbBdyCc-5GW-hxXoz3j1PDyBRzVGYquihseVoIuOuk_6NieVwc0a7DOJOlpt4nJjttdRQGt5D9f_0_LB89pUwUXuJIKQ5DKWVPpT6PrX5YHxwa4rIDC3_8mXcT6w9qF2TbQwjU7K-baRanWqpHZT9v-d0Mc2GA2aGIIAl_urhGcMLDiMwkEv15OcZ5pBn190s4M0HcoelVKis7cf4PeOcBkgAPUfml-yJlNUWOnFNYVABI0wjdj0NrYki5vLdNt8ZoVsQ9PyNBRWD0815tSmGbVkILJEdvJ-vc';
const ErrorHandler = (error) => {
	return "Some Error Has Occured, Please Try again"; 
}

export const dkycApi = (url, payload, contentType = null, errorHandler = ErrorHandler) => {
	
	const headers = {
		headers: {
			Authorization: `Bearer ${apiKey}`,
			'Content-Type': contentType,
		}
	}
	return new Promise((resolve, reject) => {
		axios.post(`${dkycUrl}${url}`, payload, headers)
			.then((response) => {
				resolve(response.data)
			})
			.catch((error) => {
			// 	const err = ErrorHandler(error?.response?.data);
				reject(error)
			})
	})
};
export const dkycAppApi = (url, payload, contentType = null, errorHandler = ErrorHandler) => {
	
	const headers = {
		headers: {
			Authorization: `Bearer ${apiAppKey}`,
			'Content-Type': contentType,
		}
	}
	return new Promise((resolve, reject) => {
		axios.post(`${dkycAppUrl}${url}`, payload, headers)
			.then((response) => {
				resolve(response.data)
			})
			.catch((error) => {
			// 	const err = ErrorHandler(error?.response?.data);
				reject(error)
			})
	})
};    