import React, { useEffect, useState } from 'react';
import AverageTimeCard from './AverageTimeCard';
import AverageTimeBarChart from './AverageTimeBarChart';
function AverageTimeChart(props) {
 
  const [kycCompletion , setKycCompletion] = useState(0);
  const [kycVerification , setKycVerification ] = useState(0);
  const [kycOnboarding , setKycOnboarding] = useState(0);

  useEffect(()=>{
    if(props.data){
      console.log(props.data ,'avgTimeTo')
      if(props.data[0]){

        setKycCompletion(parseInt(props.data[0].average_time_to_complete_kyc_in_hours));
      }
      if(props.data[0]){

        setKycVerification(parseInt(props.data[0].average_time_to_verify_kyc_in_hours));
      }
    }
  },[props.data])

  if (kycCompletion === null) {
    // Data is not yet available, you can render a loading indicator or message here
    return <div>Loading...</div>;
  }

  return (<>
    <div className="card card-average-time mb-4">
      <div className="card-body pb-0">
        <div className="row">
          <div className="col-lg-3 col-md-4 col-12 mb-4 pe-lg-0 d-flex align-items-center">
            <div className="w-100">
              <AverageTimeCard data={{
                'label': ' Avg. Time Taken for KYC Completion',
                'time': kycCompletion + ' hrs'

              }} />
              <AverageTimeCard data={{
                'label': ' Avg. Time Taken for KYC Verification',
                'time': kycVerification + ' hrs'

              }} />
              <AverageTimeCard data={{
                'label': '   Avg. Time Taken for KYC Onboarding',
                'time': '- hrs '

              }} />
            </div>
          </div>
          <div className="col-lg-9 col-md-8 col-12 mb-4">
            <div className="avg-bar-chart">
              {/* <img
                src="images/dashboard-images/average time.png"
                className="w-100"
                alt="overview image"
              /> */}
             <AverageTimeBarChart data = {props.graphData} />
            </div>
          </div>
        </div>
      </div>
    </div>

  </>)
}
export default AverageTimeChart;