import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

function JourneySource(props) {
  const [chartData, setChartData] = useState([
    {
      "App": "0",
      "Web": "0",
      "RM": "0"
    }
  ])
  const [WebData , setWebData] = useState('0');
  const [appData , setAppData] = useState('0');
  useEffect(() => {
    if (props.data) {
      if(props.data[0]['Web']){
        setWebData(parseInt(props.data[0]['Web']));
      }
      if(props.data[0]['App']){
        setAppData(parseInt(props.data[0]['App']));
      }
      setChartData(props.data);
    }
  }, [props.data])


  const options = {
    chart: {
      type: 'donut',
      width: 380,
      borderRadius: 5000,
      radius: 30 // Adjust the border radius here
    },
    labels: ['web', 'app'],
    dataLabels: {
      enabled: false,
      position: 'left', // Disable data labels (percentage)
    },
    colors: ['#00FFC1', '#008490'], 
    legend: {
      position: 'left'
    },
  //   title: {
  //     text: 'Lead Source',
  //     style : {
  //         fontFamily: 'Poppins',
  //         fontSize: '20px',
  //         fontWeight: 500,
  //         lineHeight: '30px',
  //         letterSpacing: '-0.0375em',
  //         textAlign: 'left'
  //       }
  // },
    
  }

  const series = [WebData, appData];
  return (<>
    <div className="card card-journey-source h-100">
      <div className="card-body">
        <h2 className="card-title mb-0">Journey Source</h2>
        <h2>{parseInt(chartData[0].Web) + parseInt(chartData[0].App)}</h2>
        <div className="">
         
          <><div className="donut">
          <img className='image'
                    src="images/dashboard-images/devices.svg"
                    alt="overview image"
                  />
            <ReactApexChart options={options} series={series} type="donut" width="100%" /> 
          </div></>
        </div>
      </div>
    </div>
  

  </>)
}
export default JourneySource;