import React, { useEffect, useState } from 'react'
import Chart from "react-apexcharts";

export default function DropoutsLineChart(props) {
  const [chartData, setChartData] = useState([]);
  const [isDateBased, setIsDateBased] = useState(true);

  useEffect(() => {
    if (props.data) { setChartData(props.data); }
  }, [props.data]);
  useEffect(() => {
    if (props.duration) {
     if(props.duration  == 'one_year' || props.duration == 'last_three_month' || props.duration == 'last_six_month' ){
      setIsDateBased(false);
     }else{
      setIsDateBased(true);
     }
    }
  }, [props.duration]);
  
  const xLabels = [];
  const getMonthName = (monthNumber) => {
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
    return monthNames[parseInt(monthNumber) - 1];
  };
  const getFormattedDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = getMonthName(date.getMonth() + 1);
    const year = date.getFullYear();

    return `${day}-${month}`;
  };
  const months = Object.keys(chartData);

  if (isDateBased) {
    months.forEach((item) => {
      xLabels.push(getFormattedDate(item));
    });
  } else {
    months.forEach((item) => {
      xLabels.push(getMonthName((item)));
    });
  }
  // Prepare datasets for each step
  const arraysOfCounts = Array.from({ length: 15 }, () => []);

  // Loop through the indices (0 to 15)
  for (let index = 0; index < 15; index++) {
    // Loop through the months '09', '10', '11'
    for (const month in chartData) {
      const stepData = chartData[month].find((entry) => entry.step === index + 1);
      arraysOfCounts[index].push(stepData ? parseInt(stepData.count) : 0);
    }
  }

  // Map arraysOfCounts to series data
  const series = arraysOfCounts.map((countArray, index) => ({
    name: `Step ${index + 1}`,
    data: countArray,
  }));

  const options = {
    chart: {
      id: "basic-bar",
      toolbar: {
        show: false,
      },
    },

    xaxis: {
      categories: xLabels,
    },
  };

  return (
    <>
      <div className="app">
        <div className="row">
          <div className="mixed-chart">
            <Chart
              options={options}
              series={series}
              type="line"
              width="100%"
            />
          </div>
        </div>
      </div>
    </>
  )
}




