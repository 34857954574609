import React, { useEffect, useState } from 'react';
import PageNavigations from './PageNavigations';
import Overview from './Overview/Overview';
import Verification from './Verification/Verification';
import JourneySource from './JourneySource/JourneySource';
import LeadSource from './LeadSource/LeadSource';
import AverageTimeChart from './AverageTime/AverageTimeChart';
import StepwiseKYC from './Dropouts/StepwiseKYC';
import TimeElapsed from './TimeElapsed/TimeElapsed';
import { dkycApi, dkycAppApi } from '../ApiServices/common.js';
import axios from 'axios';

export const Dashboard = (props) => {
    console.log(props, 'dashboard');

    const [verificationData, setVerificationData] = useState({
        bigulVerified: 0,
        bigulRejected: 0,
        bonanzaVerified: 0,
        bonanzaRejected: 0,
    });
    const [AvgTimeData, setAvgTimeData] = useState({});
    const [leads, setLeads] = useState('');
    const [kycDone, setKycDone] = useState('');
    const [onBoarded, setOnBoarded] = useState('');
    const [dropouts, setDropouts] = useState('');
    const [JourneySourceData, setJournySourceData] = useState();
    const [stackData, setStackData] = useState([]);
    const [LeadSourceData, setLeadSourceData] = useState([])
    const [StepwiseKYCTableData, setStepwiseKYCTableData] = useState([]);
    const [AverageTimeGraphData, setAverageTimeGraphData] = useState({});
    const [VerificationGraphData,setVerificationGraphData] =useState([]);
    const [stageWiseDropoutGraphData , setStageWiseDropoutGraphData] = useState({});
    const [timeElapsedData ,setTimeElapsedData] = useState({});
    const [role , setRole] = useState('')

    const tabsArray = [
        { eventKey: '1', title: "" },
        { eventKey: '2', title: "Bigul" },
        { eventKey: '3', title: "Bonanza" }
    ];
    function findTitleByEventKey(eventKey, tabsArray) {
        const tab = tabsArray.find(tab => tab.eventKey === eventKey);
        // setRole(tab ? tab.title : '')
        return tab ? tab.title : '';
    }

    // const roleArray = [{role}]
    const payload = {
        'duration': props.duration,
        'role': findTitleByEventKey(props.role, tabsArray),
        'startdate': '',
        'enddate': ''
    }
    useEffect(() => {
    //     axios.post('https://dkyc.bonanzaonline.com/ekycapi/stagewise_dropouts',
    //     { 'duration': 'last_seven_days',
    //     'role': '',
    //     'Authorization' : 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImNkMDUxZGExMDc2MDg1NzVkOGE2NDZhZWM5ZGM0NTI1NGRiYjE4ZjM0OThiOThhZDYwYWI2MDI1ZGU2MjRlZDJhOGIxZTk5YTQ2OTk4NDJiIn0.eyJhdWQiOiI1IiwianRpIjoiY2QwNTFkYTEwNzYwODU3NWQ4YTY0NmFlYzlkYzQ1MjU0ZGJiMThmMzQ5OGI5OGFkNjBhYjYwMjVkZTYyNGVkMmE4YjFlOTlhNDY5OTg0MmIiLCJpYXQiOjE2ODk4NTM5NDYsIm5iZiI6MTY4OTg1Mzk0NiwiZXhwIjoxNzIxNDc2MzQ2LCJzdWIiOiIxMiIsInNjb3BlcyI6W119.FBVXv3ilHKnz0t_I5B1tg54kjwVzM59PMIVCH5F7NZtwqxumiq2I8HsNajHw1w-2oVxlkgHZHvHAx9TmlAJFC55ffdNo3URC3At4_nqMPMsJ2IhO-1FXEqX5iDGN7F3Fr42chvNw3caB7dNBG207zgpJm7XpK-18iqVm6oW034hwKHg8uHz8BhPd_0DW8i9ouQf0RI7BW-W6Q6tQtNyIcLwLrLunQtzAci4rqDaOViESqiCL8bb0ZLtNAtY_DsbLUzOQ1NJF5R9xLhH_yughbeNEdmQgKjuueDBKkiPWvqxZX4GdqtvZKxt4ZgpAHR2m8CvhYWL2HbwmSl9TiYDAvKcAi8gGgI06HodxToP1j2KftfG2c4tTrJ9i2hGbD9o7eV12EQ0ZnMbBdyCc-5GW-hxXoz3j1PDyBRzVGYquihseVoIuOuk_6NieVwc0a7DOJOlpt4nJjttdRQGt5D9f_0_LB89pUwUXuJIKQ5DKWVPpT6PrX5YHxwa4rIDC3_8mXcT6w9qF2TbQwjU7K-baRanWqpHZT9v-d0Mc2GA2aGIIAl_urhGcMLDiMwkEv15OcZ5pBn190s4M0HcoelVKis7cf4PeOcBkgAPUfml-yJlNUWOnFNYVABI0wjdj0NrYki5vLdNt8ZoVsQ9PyNBRWD0815tSmGbVkILJEdvJ-vc'
    // },'application/x-www-form-urlencoded')
        dkycApi('/getTotalLeads', payload, 'application/x-www-form-urlencoded')
            .then((res) => {
                if (res.success === true) {
                    setLeads(res.data[0].count);
                  
                }
                else {
                    setLeads('0');
                }
            })
            .catch(() => {
                setLeads('0');
            })
        dkycApi('/getTotalKYCDone', payload, 'application/x-www-form-urlencoded')
            .then((res) => {
                if (res.success === true) {
                    setKycDone(res.data[0].count);
                  
                }
                else {
                    setKycDone('0');
                }
            })
            .catch(() => {
                setKycDone('0');
            })
        dkycApi('/getTotalDropouts', payload, 'application/x-www-form-urlencoded')
            .then((res) => {
                if (res.success === true) {
                    setDropouts(res.data[0].count);
                   
                }
                else {
                    setDropouts('0');
                }
            })
            .catch(() => {
                setDropouts('0');
            })
        dkycApi('/getTotalOnboarded', payload, 'application/x-www-form-urlencoded')
            .then((res) => {
                if (res.success === true) {
                    // setOnBoarded(res.data[0].count);
                }
                else {
                    setOnBoarded('0');
                }
            })
        dkycApi('/kyc_and_dropout', payload, 'application/x-www-form-urlencoded')
            .then((res) => {
                if (res.success === true) {
                   
                    setStackData(res.data);
                }
                else {
                    setStackData([]);
                }
            })
            .catch((err) => {
                setStackData([]);
            })
        dkycApi('/getJourneySource', payload, 'application/x-www-form-urlencoded')
            .then((res) => {
                if (res.success === true) {
                    setJournySourceData(res.data);
                }
                else {
                    setJournySourceData([]);
                }
            })
            .catch(() => {
                setJournySourceData([ {
                    "App": "0",
                    "Web": "0",
                    "RM": "0"
                }]);
            })

        dkycApi('/getVerificationDetails', payload, 'application/x-www-form-urlencoded')
            .then((res) => {
                if (res.success === true) {
                    setVerificationData({
                        ...verificationData,
                        bigulVerified: res.data[0].bigul_verified,
                        bonanzaVerified: res.data[0].bonanza_verified,
                        bigulRejected: res.data[0].bigul_rejected,
                        bonanzaRejected: res.data[0].bonanza_rejected,
                    });
                }
                else {

                }
            })
            .catch(() => {

            })
        dkycApi('/diy_and_assisted', payload, 'application/x-www-form-urlencoded')
            .then((res) => {
                if (res.success === true) {
                    setVerificationGraphData(res.data);
                }
                else {

                }
            })
            .catch(() => {

            })
        dkycApi('/stagewise_dropouts', payload, 'application/x-www-form-urlencoded')
            .then((res) => {
                if (res.success === true) {
                    console.log(res.data ,'stepwabcd');
                   setStepwiseKYCTableData(res.data);
                }
                else {

                }
            })
            .catch(() => {
            })
        dkycApi('/getLeadSource', payload, 'application/x-www-form-urlencoded')
            .then((res) => {
                if (res.success === true) {
                    setLeadSourceData(res.data[0]);
                }
                else {

                }
            })
            .catch(() => {
            })
        dkycApi('/getAverageTime', payload, 'application/x-www-form-urlencoded')
            .then((res) => {
                if (res.success === true) {
                    setAvgTimeData(res.data);
                }
                else {
                    setAvgTimeData({});
                }
            })
            .catch(() => {
                setAvgTimeData({})
            })
        dkycApi('/getAverageTimeGraph', payload, 'application/x-www-form-urlencoded')
            .then((res) => {
                if (res.success === true) {
                    setAverageTimeGraphData(res.data);
                }
                else {

                }
            })
            .catch(() => {
            })
        dkycApi('/stagewise_dropouts_graph', payload, 'application/x-www-form-urlencoded')
            .then((res) => {
                if (res.success === true) {
                    setStageWiseDropoutGraphData(res.data);
                }
                else {

                }
            })
            .catch(() => {
            })
        dkycApi('/getTimeElapsed', payload, 'application/x-www-form-urlencoded')
            .then((res) => {
                if (res.success === true) {
                    setTimeElapsedData(res.data);
                }
                else {
                    setTimeElapsedData({});
                }
            })
            .catch(() => {
            })
    }, [props])

    return (
        <>
            <Overview leads={leads}
                kycDone={kycDone}
                onBoarded={onBoarded}
                dropouts={dropouts}
                stackData={stackData} />
            <Verification 
            verificationData={verificationData}
             graphData = {VerificationGraphData}
             role = {props.role}
           />
            {<div className={`row ${findTitleByEventKey(props.role, tabsArray) === 'Bonanza' ? 'donutDisplay':''} `} 
            // style={{display : 'none'}}
             >
                <div className="col-md-5 mb-4">
                    <JourneySource data={JourneySourceData} /> 
                </div>
                <div className="col-md-7 mb-4">
                    <LeadSource data={LeadSourceData} />
                </div>
            </div>}
            <AverageTimeChart data={AvgTimeData} graphData={AverageTimeGraphData} />
            <StepwiseKYC data={StepwiseKYCTableData} graphData = {stageWiseDropoutGraphData} duration = {props.duration}/>
            <TimeElapsed data = {timeElapsedData}/>
        </>
    )
}
