import React from 'react';
import ReactApexChart from 'react-apexcharts';

const LineChart = () => {
    const data = {
        "09": [
            {
                "step": 1,
                "count": 55
            },
            {
                "step": 2,
                "count": 7
            },
            {
                "step": 3,
                "count": 1
            },
            {
                "step": 4,
                "count": 2
            },
            {
                "step": 5,
                "count": 3
            },
            {
                "step": 6,
                "count": 0
            },
            {
                "step": 7,
                "count": 0
            },
            {
                "step": 8,
                "count": 0
            },
            {
                "step": 9,
                "count": 1
            },
            {
                "step": 10,
                "count": 0
            },
            {
                "step": 11,
                "count": 0
            },
            {
                "step": 12,
                "count": 0
            },
            {
                "step": 13,
                "count": 1
            },
            {
                "step": 14,
                "count": 0
            },
            {
                "step": 15,
                "count": 2
            },
            {
                "count": 1
            }
        ],
        "10": [
            {
                "step": 1,
                "count": 414
            },
            {
                "step": 2,
                "count": 435
            },
            {
                "step": 3,
                "count": 116
            },
            {
                "step": 4,
                "count": 399
            },
            {
                "step": 5,
                "count": 275
            },
            {
                "step": 6,
                "count": 5
            },
            {
                "step": 7,
                "count": 53
            },
            {
                "step": 8,
                "count": 149
            },
            {
                "step": 9,
                "count": 106
            },
            {
                "step": 10,
                "count": 17
            },
            {
                "step": 11,
                "count": 71
            },
            {
                "step": 12,
                "count": 38
            },
            {
                "step": 13,
                "count": 3
            },
            {
                "step": 14,
                "count": 56
            },
            {
                "step": 15,
                "count": 33
            },
            {
                "count": 210
            }
        ],
        "11": [
            {
                "step": 1,
                "count": 350
            },
            {
                "step": 2,
                "count": 326
            },
            {
                "step": 3,
                "count": 153
            },
            {
                "step": 4,
                "count": 544
            },
            {
                "step": 5,
                "count": 586
            },
            {
                "step": 6,
                "count": 17
            },
            {
                "step": 7,
                "count": 22
            },
            {
                "step": 8,
                "count": 41
            },
            {
                "step": 9,
                "count": 81
            },
            {
                "step": 10,
                "count": 18
            },
            {
                "step": 11,
                "count": 16
            },
            {
                "step": 12,
                "count": 1
            },
            {
                "step": 13,
                "count": 3
            },
            {
                "step": 14,
                "count": 27
            },
            {
                "step": 15,
                "count": 43
            },
            {
                "count": 138
            }
        ]
    }
  const months = Object.keys(data);

  // Prepare datasets for each step
  const arraysOfCounts = Array.from({ length: 15 }, () => []);

  // Loop through the indices (0 to 15)
  for (let index = 0; index < 15; index++) {
    // Loop through the months '09', '10', '11'
    for (const month in data) {
      const stepData = data[month].find((entry) => entry.step === index + 1);
      arraysOfCounts[index].push(stepData ? parseInt(stepData.count) : 0);
    }
  }

  // The `arraysOfCounts` array now contains 15 arrays, each representing the counts for the same index across different months
  console.log(arraysOfCounts, 'arraysOfCounts11');
  console.log(months, 'months11');

  // Map arraysOfCounts to series data
  const series = arraysOfCounts.map((countArray, index) => ({
    name: `Step ${index + 1}`,
    data: countArray,
  }));

  const options = {
    chart: {
      id: "basic-bar",
      toolbar: {
        show: false,
      },
    },

    xaxis: {
      categories: months.sort(),
    },
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="line"
    //   height={400}
      width='100%' // Adjust the height as needed
    />
  );
};

export default LineChart;
